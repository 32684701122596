var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showPopup)?_c('div',{staticClass:"cookies-line"},[_c('div',{staticClass:"new-modal-cookies"},[_c('div',{staticClass:"cookies__title"},[_vm._v("\n        "+_vm._s(_vm.getText('gdprMainPopupDescription'))+"\n        "),_c('a',{attrs:{"href":"/privacy#cookies","target":"blank"}},[_vm._v("\n          "+_vm._s(_vm.getText('gdprMainPopupPrivacyLinkText'))+"\n        ")]),_vm._v("\n        "+_vm._s(_vm.getText('gdprMainPopupDetailedDescription'))+"\n      ")]),_vm._v(" "),_c('button',{staticClass:"cookies-btn",attrs:{"type":"button"},on:{"click":_vm.acceptCookiesNew}},[_vm._v("\n        "+_vm._s(_vm.getText('gdprMainPopupAcceptAllButtonText'))+"\n      ")])])]):_vm._e(),_vm._v(" "),(false)?_c('div',{staticClass:"cookie-modal cookie-modal-small",class:{ _active: _vm.state === 'small' }},[_c('div',{staticClass:"cookie-modal-small__text"},[_vm._v("\n      "+_vm._s(_vm.getText('gdprMainPopupDescription'))+"\n      "),_c('span',{on:{"click":_vm.openDesc}},[_vm._v(_vm._s(_vm.getText('gdprMainPopupReadMoreButtonText', _vm.texts.mainPopup.readMore)))])]),_vm._v(" "),_c('div',{staticClass:"cookie-modal-small__buttons"},[_c('button',{staticClass:"cookie-modal__button _purple",on:{"click":_vm.acceptCookies}},[_vm._v("\n        "+_vm._s(_vm.getText(
            'gdprMainPopupAcceptAllButtonText',
            _vm.texts.mainPopup.acceptAll
          ))+"\n      ")]),_vm._v(" "),_c('button',{staticClass:"cookie-modal__button _white",on:{"click":_vm.openSettings}},[_vm._v("\n        "+_vm._s(_vm.getText(
            'gdprMainPopupOpenDetailedButtonText',
            _vm.texts.mainPopup.settings
          ))+"\n      ")])])]):_vm._e(),_vm._v(" "),(false)?_c('div',{ref:"scroll-modal-1",staticClass:"cookie-modal cookie-modal-desc",class:{ _active: _vm.state === 'desc' }},[_c('div',{ref:"scroll-text-1",staticClass:"cookie-modal-desc__text",domProps:{"innerHTML":_vm._s(_vm.getDetailedMainPopupText())}}),_vm._v(" "),_c('div',{staticClass:"cookie-modal-desc__buttons"},[_c('button',{staticClass:"cookie-modal__button _purple",on:{"click":_vm.acceptCookies}},[_vm._v("\n        "+_vm._s(_vm.getText(
            'gdprMainPopupAcceptAllButtonText',
            _vm.texts.mainPopup.acceptAll
          ))+"\n      ")]),_vm._v(" "),_c('button',{staticClass:"cookie-modal__button _white",on:{"click":_vm.openSettings}},[_vm._v("\n        "+_vm._s(_vm.getText(
            'gdprMainPopupOpenDetailedButtonText',
            _vm.texts.mainPopup.settings
          ))+"\n      ")])])]):_vm._e(),_vm._v(" "),(false)?_c('div',{ref:"scroll-modal-2",staticClass:"cookie-modal cookie-modal-settings",class:{ _active: _vm.state === 'settings' }},[_c('div',{ref:"scroll-text-2",staticClass:"cookie-modal-settings__list"},[_c('div',{staticClass:"cookie-modal-settings__el"},[_c('div',{staticClass:"cookie-modal-settings__text"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.getText('gdprCookiesRequiredTitle', _vm.texts.required.title)))])]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.getText(
                'gdprCookiesRequiredShortDescription',
                _vm.texts.required.shortDesc
              ))+"\n          ")]),_vm._v(" "),(!_vm.texts.required.longDescIsActive)?_c('p',[_c('span',{staticClass:"cookie-modal-settings__more",on:{"click":function($event){_vm.texts.required.longDescIsActive = true}}},[_vm._v(_vm._s(_vm.getText(
                  'gdprDetailedPopupReadMoreButtonText',
                  _vm.texts.required.readMore
                )))])]):_c('p',[_vm._v("\n            "+_vm._s(_vm.getText(
                'gdprCookiesRequiredLongDescription',
                _vm.texts.required.longDesc
              ))+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"cookie-modal-settings__checkbox"},[_c('gdpr-cookie-modal-checkbox',{attrs:{"checkbox-name":"required","is-checked":_vm.decisionsRequired,"is-disabled":true},on:{"change":_vm.setGdprDecision}})],1)]),_vm._v(" "),_c('div',{staticClass:"cookie-modal-settings__el"},[_c('div',{staticClass:"cookie-modal-settings__text"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.getText('gdprCookiesAnalyticsTitle', _vm.texts.analytics.title)))])]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.getText(
                'gdprCookiesAnalyticsShortDescription',
                _vm.texts.analytics.shortDesc
              ))+"\n          ")]),_vm._v(" "),(!_vm.texts.analytics.longDescIsActive)?_c('p',[_c('span',{staticClass:"cookie-modal-settings__more",on:{"click":function($event){_vm.texts.analytics.longDescIsActive = true}}},[_vm._v(_vm._s(_vm.getText(
                  'gdprDetailedPopupReadMoreButtonText',
                  _vm.texts.analytics.readMore
                )))])]):_c('p',[_vm._v("\n            "+_vm._s(_vm.getText(
                'gdprCookiesAnalyticsLongDescription',
                _vm.texts.analytics.longDesc
              ))+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"cookie-modal-settings__checkbox"},[_c('gdpr-cookie-modal-checkbox',{attrs:{"checkbox-name":"analytics","is-checked":_vm.decisionsAnalytics,"is-disabled":false},on:{"change":_vm.setGdprDecision}})],1)]),_vm._v(" "),_c('div',{staticClass:"cookie-modal-settings__el"},[_c('div',{staticClass:"cookie-modal-settings__text"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.getText('gdprCookiesMarketingTitle', _vm.texts.marketing.title)))])]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.getText(
                'gdprCookiesMarketingShortDescription',
                _vm.texts.marketing.shortDesc
              ))+"\n          ")]),_vm._v(" "),(!_vm.texts.marketing.longDescIsActive)?_c('p',[_c('span',{staticClass:"cookie-modal-settings__more",on:{"click":function($event){_vm.texts.marketing.longDescIsActive = true}}},[_vm._v(_vm._s(_vm.getText(
                  'gdprDetailedPopupReadMoreButtonText',
                  _vm.texts.marketing.readMore
                )))])]):_c('p',[_vm._v("\n            "+_vm._s(_vm.getText(
                'gdprCookiesMarketingLongDescription',
                _vm.texts.analytics.longDesc
              ))+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"cookie-modal-settings__checkbox"},[_c('gdpr-cookie-modal-checkbox',{attrs:{"checkbox-name":"marketing","is-checked":_vm.decisionsMarketing,"is-disabled":false},on:{"change":_vm.setGdprDecision}})],1)])]),_vm._v(" "),_c('div',{staticClass:"cookie-modal-settings__buttons"},[_c('button',{staticClass:"cookie-modal__button _purple",on:{"click":_vm.saveSettings}},[_vm._v("\n        "+_vm._s(_vm.getText(
            'gdprDetailedPopupSaveButtonText',
            _vm.texts.mainPopup.saveSettings
          ))+"\n      ")]),_vm._v(" "),_c('button',{staticClass:"cookie-modal__button _white",on:{"click":_vm.declineSettings}},[_vm._v("\n        "+_vm._s(_vm.getText(
            'gdprDetailedPopupCancelButtonText',
            _vm.texts.mainPopup.declineSettings
          ))+"\n      ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }